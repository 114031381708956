import './Footer.css'

function Footer() {
  return (
    <footer>
      Copyright 2023 | Summer Mandin | UI / UX Portfolio
    </footer>
  );
}

export default Footer;