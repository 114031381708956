import { NavLink } from 'react-router-dom'
import Brand from './assets/logo.png'
import './Navbar.css'
import resumePdf from './assets/summer-resume.pdf'

const Navbar = () => {
  return (
    <nav className="navbar">
      <div className="container">
        <div className="logo">
        <NavLink to="/"><img className="logo" src={Brand} alt='Brand Logo'></img></NavLink>
        </div>
        <div className="nav-elements">
          <ul>
            <li className='element'>
              <NavLink to="/">Home</NavLink>
            </li>
            <li className='element'>
              <NavLink to="/projects">Projects</NavLink>
            </li>
            <li className='element'>
              <NavLink to="/about">About</NavLink>
            </li>
            <li className='element'>
              <a href={resumePdf} target='_blank' rel='noreferrer'>Resume</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}

export default Navbar