import './Projects.css';
import Navbar from './Navbar';
import Footer from './Footer';
import kaushal_clinic_logo from './assets/kaushal-clinic-logo.png'
import cover_pic from './assets/Cover.png'
import okta_threat_intel_logo from './assets/okta-threat-intel-logo.jpg'

export default function Projects() {
  return (
    <div>
      <Navbar />
      <div className='project_content'>
        <h1>Projects</h1>

        <div className='project'>
          <img id="kaushal_clinic_logo" src={kaushal_clinic_logo} alt='kaushal-clinic-logo' />
          <div className='column'>
          <h1>Kaushal Clinic Logo and website design.</h1>
          <p>Designed a brand logo and a website for kaushal clinic <a href='https://www.kaushalclinic.com' target="_blank" rel="noreferrer">website.</a></p>
          </div>
        </div>

        <div className='project'>
          <img id="okta_threat_intel_logo" src={okta_threat_intel_logo} alt='okta-threat-intel-logo' />
          <div className='column'>
          <h1>Okta Threat Intelligence team logo</h1>
          <p>Designed a logo for Okta's threat Intelligence team.</p>
          </div>
        </div>

        <div className='project'>
          <img id="cover_pic" src={cover_pic} alt='book-design-logo' />
          <div className='column'>
          <h1>Book Redesign</h1>
          <p>Redesigned a book cover.</p>
          </div>
        </div>

      </div>
      <Footer />
    </div>
  );
}