import './Home.css';
import summer from './assets/summer.png'
import Navbar from './Navbar';
import Footer from './Footer';

function Home() {
  return (
    <div className="Home">
      <Navbar />
      <div id="main-content">
        <div id="row-one">
        <p id="main-text">
          <span className="main-text">Summer Mandin</span>
          <br />
          <span style={{fontSize: '4rem'}}>UI & UX Designer</span>
          <br/>
          <br/>
          <span style={{fontSize: '1.5rem'}}>
          I am a UI/UX & Graphic Designer based in Vancouver, BC. 
          </span>
        </p>
        </div>
        <img src={summer} alt='summmer' id='summer-photo'></img>
      </div>
      <Footer />
    </div>
  );
}

export default Home;
