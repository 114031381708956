import './About.css';
import Navbar from './Navbar';
import Footer from './Footer';
import summer_about from './assets/summer-about.jpeg'

function About() {
  return (
    <div className='About'>
      <Navbar />
      <div className='content'>
        <div className='photo'>
          <img id="summer_about" src={summer_about} alt='summer-about' />
        </div>
        <div className='about_text'>
          <h1>Hello, I’m Summer. It’s nice to meet you!</h1>
          <p>
            My name is Summer Mandin, a soon-to-be graduate 
            from BCIT’s UI/UX Designer program with a UI/UX Associate 
            Certificate. I specialize in crafting visually appealing and 
            user-centric digital experiences. Proficient in wireframing, 
            prototyping, and user research, I’m excited to bring my 
            innovative approach and fresh perspective to the design 
            world. Let’s create engaging and seamless experiences 
            together!
        </p>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default About